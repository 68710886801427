<template>
  <l-map ref="myMap" :zoom="zoom" :options="mapOptions" @update:zoom="zoomUpdate" :staticMap="staticMap" :tap="false"
    :center="center">


    <v-tooltip top v-for="(e, id) in pointsMarket" :key="id">
      <template v-slot:activator="{ on, attrs }">
        <l-marker v-bind="attrs" v-on="on" :lat-lng="[e.ubicacion.latitud, e.ubicacion.longitud]" :draggable="false"
          @click="openPopUp(e, 'marker')"
          :icon="e.unidad == 'Distribución' ? iconDistribucion : e.unidad == 'Transmisión' ? iconTransmision : e.unidad == 'Generación' ? iconGeneracion : ninguno">
          <l-popup ref="popup" v-if="controlsShow">
            <h3 class="mt-6 pr-4">{{ e?.titulo }}</h3>

            <v-img class="mt-4" v-if="e?.portada?.type"
              :src="`data:image/${e?.portada?.type};base64,${e?.portada.data}`" style="border-radius: 10px;">
            </v-img>
            <p class="text-caption">
              {{ e.descripcion }}
            </p>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn> cancelar </v-btn> -->
              <v-btn color="info" style="border-radius: 10px" elevation="0" class="px-4" @click="sendProyect(e)">
                <span style="color: #fff !important;    text-transform: initial !important;">Ver más</span>
              </v-btn>
            </v-card-actions>
          </l-popup>
        </l-marker>
      </template>
      <span>{{ e.nombreCorto }}</span>
    </v-tooltip>


    <l-tile-layer :url="url" />
    <LPolyline v-for="(l, index) in lines" :color="l.color" :weight="5" :key="index" :lat-lngs="l.lines">
      <l-popup ref="popup2" v-if="controlsShow">
        <h3 class="pr-4">{{ l?.titulo }}</h3>
        <p class="text-caption">
          {{ l.descripcion }}
        </p>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn> cancelar </v-btn> -->
          <v-btn color="info" style="border-radius: 10px" elevation="0" class="px-4" @click="sendProyect(l)">
            <span style="color: #fff !important;    text-transform: initial !important;">Ver más</span>
          </v-btn>
        </v-card-actions>
      </l-popup>
    </LPolyline>
    <LPolygon v-for="(p, index) in poligons" :key="index" :stroke="false" fillOpacity="0.5" :lat-lngs="p.coordenadas"
      :fillColor="p.color">
      <l-popup ref="popup3" v-if="controlsShow">
        <h3 class="pr-4">{{ p?.titulo }}</h3>
        <p class="text-caption">
          {{ p.descripcion }}
        </p>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn> cancelar </v-btn> -->
          <v-btn color="info" style="border-radius: 10px" elevation="0" class="px-4" @click="sendProyect(p)">
            <span style="color: #fff !important;    text-transform: initial !important;">Ver más</span>
          </v-btn>
        </v-card-actions>
      </l-popup>
    </LPolygon>

    <!-- <div class="btn-refresh" @click="centerZoom" v-if="controlsShow">
      <v-btn fab dark color="#fff">
        <v-icon color="#ff5900"> fa-solid fa-ubicacion-crosshairs </v-icon>
      </v-btn>
    </div> -->
  </l-map>
</template>

<script>
import L, { point } from "leaflet";
import axios from "axios";
import { mapActions, mapState } from 'vuex';
// import { OpenStreetMapProvider } from "leaflet-geosearch";
import { latLng, divIcon } from "leaflet";
import {
  LMap,
  LTileLayer,
  LIcon,
  LMarker,
  LPolygon,
  LPopup,
  LControlZoom,
  LFeatureGroup,
  LControl,
  LTooltip,
  LPolyline,
} from "vue2-leaflet";


require("leaflet-routing-machine");
require("leaflet-control-geocoder");

export default {
  name: "Mapa",
  props: ["pointsMarket", 'staticMap', 'poligons', 'lines', 'controlsShow'],
  components: {
    LMap,
    LTileLayer,
    LFeatureGroup,
    LMarker,
    LPolygon,
    "l-icon": LIcon,
    "l-popup": LPopup,
    "l-control-zoom": LControlZoom,
    "l-tooltip": LTooltip,
    LPolyline,
  },
  data() {
    return {
      optionsPopUp: {
        width: "400px",
      },
      options: {},
      menuOpen: false,
      waypoints: [],
      allPointsMaps: [],
      zoom: 10,
      select: 0,
      marker: true,
      caller: null,
      map: null,
      currentZone: null,
      selectedPoint: null,
      center: latLng(14.0625764, -87.2226859),
      url: "https://tile.jawg.io/jawg-terrain/{z}/{x}/{y}{r}.png?access-token=iEIhXFKiBQgbYsHy0112N2SH88oI5eUzmnH45nUlM0mkiV8Y8wCXIhxPF7hPR5jX",
      // url: "http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
      // url: "http://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}.png",
      // url: "https://cartocdn_{s}.global.ssl.fastly.net/base-flatblue/{z}/{x}/{y}.png",
      // url: "https://cartocdn_{s}.global.ssl.fastly.net/base-midnight/{z}/{x}/{y}.png",
      urls: [
        "https://tile.jawg.io/jawg-terrain/{z}/{x}/{y}{r}.png?access-token=iEIhXFKiBQgbYsHy0112N2SH88oI5eUzmnH45nUlM0mkiV8Y8wCXIhxPF7hPR5jX",
        "https://tile.jawg.io/jawg-streets/{z}/{x}/{y}.png?access-token=iEIhXFKiBQgbYsHy0112N2SH88oI5eUzmnH45nUlM0mkiV8Y8wCXIhxPF7hPR5jX",
        "https://tile.jawg.io/jawg-lagoon/{z}/{x}/{y}.png?access-token=iEIhXFKiBQgbYsHy0112N2SH88oI5eUzmnH45nUlM0mkiV8Y8wCXIhxPF7hPR5jX",
        "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
        "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
        "http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
        "http://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}.png",
        "https://cartocdn_{s}.global.ssl.fastly.net/base-flatblue/{z}/{x}/{y}.png",
        "https://cartocdn_{s}.global.ssl.fastly.net/base-midnight/{z}/{x}/{y}.png",
      ],
      routeControl: "",
      currentZoom: 11.5,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 1,
      },
      icon: L.icon(this.getImage),
      showMap: false,
      businessLogo: null,


    };
  },
  computed: {
    ...mapState(["allProyectsGlobal", "selectProyect"]),
    iconTransmision() {
      return L.icon({
        iconUrl: require("@/assets/icons-03.svg"),
        shadowUrl: require("@/assets/map-marker-01.svg"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [19, 53],
        shadowAnchor: [35, 60],
      });
    },
    iconGeneracion() {
      return L.icon({
        iconUrl: require("@/assets/icons-02.svg"),
        shadowUrl: require("@/assets/map-marker-01.svg"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [19, 53],
        shadowAnchor: [35, 60],
      });
    },
    ninguno() {
      return L.icon({
        iconUrl: require("@/assets/ninguno.svg"),
        shadowUrl: require("@/assets/map-marker-01.svg"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [19, 53],
        shadowAnchor: [35, 60],
      });
    },
    iconDistribucion() {
      return L.icon({
        iconUrl: require("@/assets/icons-01.svg"),
        shadowUrl: require("@/assets/map-marker-01.svg"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [19, 53],
        shadowAnchor: [35, 60],
      });
    },
    // poligons() {
    //   return this.geometry.coordenadas.map(e => {
    //     return [e[1], e[0]]
    //   })
    // }
  },

  methods: {
    ...mapActions(['selectProyect_']),
    openPopUp(latLng, caller) {

      this.caller = caller;
      var p = this.$refs.features.mapObject.openPopup(latLng);

    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    sendProyect(proyecto) {
      var proyect = this.allProyectsGlobal.find(x => x.id == proyecto.id)
      this.selectProyect_(proyect)

      // this.$router.push('/infoProyect');
      this.$router.push({ path: `/infoProyect/${proyecto.proyectoId}` });

    },

    centerZoom() {

      this.$nextTick(() => {
        this.map = this.$refs.myMap.mapObject;

        let points = this.pointsMarket.map((item) => {
          return latLng(item.ubicacion.latitud, item.ubicacion.longitud);
        });
        let lines = this.lines.map((item) => {
          return item.lines.map(i => {
            return latLng(i[0], i[1]);
          })
        });
        let poligons = this.poligons.map((item) => {

          return item.coordenadas.map(i => {
            return latLng(i[0], i[1]);
          })

        });
        var pointsAll = lines.concat(poligons);

        let puntos = []
        for (let i = 0; i < pointsAll.length; i++) {
          const element = pointsAll[i];
          for (let index = 0; index < element.length; index++) {
            const p = element[index];
            puntos.push(p)

          }
        }

        for (let i = 0; i < points.length; i++) {
          const p = points[i];
          puntos.push(p)
        }


        this.allPointsMaps = puntos;
        this.map.fitBounds(puntos, {
          paddingTopLeft: [50, 50],
          paddingBottomRight: [50, 50]
        });

      })
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    PaintPointer() {

      this.$nextTick(() => {

        this.map = this.$refs.myMap.mapObject;
        let pointsInfo = this.pointsMarket.map((item) => {
          return {
            titulo: item.titulo || '',
            id: item.id || '', // Asegúrate de que 'id' sea un string para que coincida con el patrón de clase CSS    
          }
        });


        var position = 0;
        this.map.eachLayer((layer) => {


          let marker = L.divIcon({
            className: `custom-marker marker-stop marker-stop-${position}`,
            html: `<div class='marker-pin'  data-type='stop'><p> ${pointsInfo[position]?.titulo}<br/>
    </p> 
     </div>`,
            iconSize: [250, 58],
            iconAnchor: [125, 58],
            popupAnchor: [125, 58],
          });

          layer.setIcon(marker);

          position += 1;
        });
      })




    },
    // markerPopUp(e, marker, update) {
    //   let container = L.DomUtil.create("div");
    //   let containerTitle;
    //   container.setAttribute("class", "waypointdescripcionContainer");
    //   let body;

    //   switch (marker) {
    //     case "stop":
    //       containerTitle = this.createTitle("Parada", container);
    //       body = L.DomUtil.create("div", "", container);
    //       body.setAttribute("class", "waypointContainerTitle");

    //       body.innerHTML = `<i class="fas fa-pennant icon"></i>`;
    //       body.innerHTML += `<span class="address1">${this.routeData[`point_${this.selectedPoint}`].address[0]
    //         }</span>`;
    //       body.innerHTML += `<span class="address2">${this.routeData[
    //         `point_${this.selectedPoint}`
    //       ].address
    //         .slice(1)
    //         .join(", ")}</span>`;
    //       body.innerHTML += `<span class="address3">${this.routeData[
    //         `point_${this.selectedPoint}`
    //       ].latLng.lat.toFixed(4)},${this.routeData[
    //         `point_${this.selectedPoint}`
    //       ].latLng.lng.toFixed(4)}</span>`;
    //       if (this.routeData[`point_${this.selectedPoint}`].comments) {
    //         body.innerHTML += `<span class="comments">${this.routeData[`point_${this.selectedPoint}`].comments
    //           }</span>`;
    //       }

    //       //buttons div

    //       let buttonsContainer = L.DomUtil.create("div");
    //       buttonsContainer.setAttribute("class", "buttonsContainer");

    //       //EDIT BUTTON
    //       let editButton = document.createElement("button");
    //       editButton.innerText = `Editar`;
    //       editButton.setAttribute("class", "edit");

    //       editButton.onclick = () => {
    //         this.editPointFromPopup();
    //       };

    //       if (buttonsContainer) {
    //         buttonsContainer.appendChild(editButton);
    //       }

    //       //DELETE BUTTON
    //       let button = document.createElement("button");
    //       button.innerText = `Eliminar`;
    //       button.setAttribute("class", "delete");

    //       button.onclick = () => {
    //         this.deletePoint();
    //       };

    //       if (buttonsContainer) {
    //         buttonsContainer.appendChild(button);
    //       }

    //       if (body) {
    //         body.appendChild(buttonsContainer);
    //       }

    //       break;
    //   }

    //   if (update) {
    //     if (this.$store.estado.sidePanel) {
    //       this.markerPopupElem.setContent(container);
    //     }
    //   } else {
    //     this.markerPopupElem = L.popup({
    //       maxWidth: 320,
    //       autoPanPaddingTopLeft: [50, 50],
    //       autoPanPaddingBottomRight: [50, 0],
    //       // closeOnClick: true,
    //       autoClose: false,
    //     })
    //       .setContent(container)
    //       .setLatLng(e.latlng)
    //       .on("popupclose, remove", (e) => {
    //         this.markerPopupElem = null;
    //       })
    //       .openOn(this.map);
    //   }
    // },
  },
  watch: {
    lines(w) {
      setTimeout(() => {
        this.centerZoom()
      }, 1000);
    },
    pointsMarket(w) {
      // this.PaintPointer()
      setTimeout(() => {
        this.centerZoom()
      }, 1000);
    }
  },
  async mounted() {


    // this.PaintPointer();
    this.$nextTick(() => {

      // POP UP
      this.centerZoom();

    });
  },
};
</script>

<style lang="scss">
span {
  color: #62c3db !important;
}

.leaflet-pane,
.leaflet-marker-icon,
.leaflet-tile-container,
.leaflet-zoom-box,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50% !important;
}

.leaflet-left {
  top: none !important;
  position: fixed !important;
  left: 10px !important;
  top: 100px !important;
}

.btn-refresh {
  position: fixed;
  right: 10px;
  z-index: 999;
}

.leaflet-bottom {
  top: none !important;
  left: 0 !important;
  bottom: 10px !important;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none !important;
  background-clip: padding-box;
  width: 56px;
  border-radius: 50px !important;
  overflow: hidden;
  box-shadow: #07061310 0px 0px 20px !important;

  .leaflet-control-zoom-in {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 10px;
  }

  .leaflet-control-zoom-out {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
  }
}

.leaflet-control-attribution {
  display: none !important;
}

.leaflet-popup-close-button {
  margin-top: 9px;
  font-weight: bold;
  font-size: 20px;
}

.routeAlt {
  box-shadow: #62c3db 0px 10px 10px;
}

.leaflet-routing-container {
  display: none;
}

.motorista {
  transition: 0.5s ease;
}

.route {
  filter: drop-shadow(2px 0px 4px #00cdbc) !important;
}

.marker-custom {
  filter: drop-shadow(2px 0px 4px #00cdbc) !important;
}

div.leaflet-marker-icon.custom-marker {
  color: #ea2227;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 50%;
    margin-left: -8px;
    height: 16px;
    width: 16px;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid #ea2227;
    box-shadow: 0px 0px 20px rgba(#ea2227, 0.8);
    border-radius: 8px;
    z-index: -1;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    height: 10px;
    width: 10px;
    box-sizing: border-box;
    background-color: white;
    border: 3px solid #ea2227;
    box-shadow: 0px 0px 20px rgba(#62c3db, 0.8);
    border-radius: 5px;
    z-index: -1;
  }

  .marker-pin {
    // pointer-events: none;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 20px rgba(#62c3db, 0.2);
    height: auto;

    padding: 5px;
    box-sizing: border-box;
    border-radius: 18px;
    text-align: center !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 0.3s ease-in-out;

    &:hover {
      transform: translateY(-5px) scale(1.2, 1.2) !important;
      box-shadow: 0px 5px 20px rgba(#62c3db, 0.5);
    }

    &::before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 50%;
      margin-left: -5px;
      height: 10px;
      width: 10px;
      transform: rotate(45deg);
      background-color: white;
      border-bottom-right-radius: 2px;
    }

    i {
      color: #62c3db;
      background-color: #62c3db;
      background-color: #7fb7ca;
      font-size: 16px;
      float: left;
      padding: 8px;
      position: relative;
      border-radius: 18px;
    }

  }
}

.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 9px !important;
  border: none;
  text-align: center;
  width: 24px;
  height: 24px;
  // font: 16px / 24px Tahoma, Verdana, sans-serif;
  color: #ffffff !important;
  text-decoration: none;
  border-radius: 16px !important;
  background: #393939 !important;
}

div.leaflet-marker-icon.custom-marker .marker-pin p {
  text-align: center;
  font-weight: revert;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 0 !important;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 999 !important;
  pointer-events: none;
}

.leaflet-popup-content {
  margin: 26px 20px 16px 20px !important;
  line-height: 1.3;
  font-size: 13px;
  font-size: 1.08333em;
  min-height: 1px;
}
</style>